'use client';

import { useEffect, useRef, useState } from 'react';
import { SubscriberForm } from '../subscriber-form';
import { motion, useInView, useScroll } from 'motion/react';
const images = ['/collections/based-punk-postage-6.jpeg', '/collections/doodles_collection.jpeg', '/collections/gmo.jpeg', '/collections/inclusive-artistry-2.jpeg', '/collections/punkish-jawns-23.jpeg', '/collections/based-punk-postage-6.jpeg', '/collections/doodles_collection.jpeg'];
export function RevealingFooter() {
  return <div className="bottom-0 mt-[70%] flex flex-col overflow-hidden md:mt-auto md:min-h-full md:justify-between" data-sentry-component="RevealingFooter" data-sentry-source-file="revealing-footer.tsx">
      <div className="z-20 m-auto flex h-full grow flex-col items-center justify-center gap-4 p-5 md:max-w-prose md:gap-6 md:p-0 lg:max-w-screen-md">
        <div className="mx-auto flex flex-col items-center gap-4 p-4 text-center text-xl leading-snug text-gray-900 md:p-0">
          <div className="font-editors-note relative max-w-prose text-2xl">
            Stay in the loop, one collection at a time.
            <br />
            <span className="font-sans text-base text-gray-400">
              Unsubscribe anytime.
            </span>
          </div>
        </div>

        <div className="flex w-full justify-center gap-2 md:max-w-prose">
          <SubscriberForm data-sentry-element="SubscriberForm" data-sentry-source-file="revealing-footer.tsx" />
        </div>
      </div>

      <ImageCarousel images={images} data-sentry-element="ImageCarousel" data-sentry-source-file="revealing-footer.tsx" />
    </div>;
}
interface ImageCarouselProps {
  images: string[];
}
function ImageCarousel({
  images
}: ImageCarouselProps) {
  const ref = useRef(null);
  const [bounceKey, setBounceKey] = useState(0);
  const lastScrollY = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const bottomThreshold = 200;
      const isAtBottom = window.innerHeight + currentScrollY >= document.documentElement.scrollHeight - bottomThreshold;
      const isScrollingDown = currentScrollY > lastScrollY.current;
      if (isAtBottom && isScrollingDown) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set a new timeout to trigger the animation
        timeoutRef.current = setTimeout(() => {
          setBounceKey(prev => prev + 1);
        }, 100); // Adjust this delay as needed
      }
      lastScrollY.current = currentScrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const getImageStyles = (index: number, totalImages: number) => {
    const middle = Math.floor(totalImages / 2);
    const distance = Math.abs(index - middle);
    if (distance === 0) return 'z-30 scale-[1.6]';
    if (distance === 1) return 'z-20 scale-[1.4]';
    if (distance === 2) return 'z-10 scale-[1.1]';
    return 'scale-[0.8]';
  };
  const getZIndex = (index: number, totalImages: number) => {
    const middle = Math.floor(totalImages / 2);
    const distance = Math.abs(index - middle);
    if (distance === 0) return 30;
    if (distance === 1) return 20;
    if (distance === 2) return 10;
    return 0;
  };
  return <div className="md:-mb-32" ref={ref} data-sentry-component="ImageCarousel" data-sentry-source-file="revealing-footer.tsx">
      {images.length > 0 && <div className="px-auto mx-auto hidden select-none justify-center -space-x-16 md:flex xl:w-full">
          {images.map((image, index) => <motion.div initial={{
        y: 0
      }} animate={{
        y: -70
      }} transition={{
        duration: 1,
        type: 'spring',
        bounce: 0.6,
        delay: index * 0.1
      }} key={`${index}-${bounceKey}`} className="relative origin-bottom" style={{
        zIndex: getZIndex(index, images.length)
      }}>
              <img src={image} className={`${getImageStyles(index, images.length)} aspect-website h-full w-full max-w-64 transform select-none overflow-hidden rounded-sm rounded-b-none shadow-xl transition`} alt={`collection ${index + 1}`} />
            </motion.div>)}
        </div>}
    </div>;
}