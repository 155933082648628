'use client';

import { motion, useInView, useScroll, useTransform } from 'motion/react';
import { useRef } from 'react';
import Foundation from '~/assets/marketplace/foundation.svg';
import Fxhash from '~/assets/marketplace/fxhash.svg';
import Highlight from '~/assets/marketplace/highlight.svg';
import Looksrare from '~/assets/marketplace/looksrare.svg';
import Manifold from '~/assets/marketplace/manifold.svg';
import Opensea from '~/assets/marketplace/opensea.svg';
import Superrare from '~/assets/marketplace/superrare.svg';
import Zora from '~/assets/marketplace/zora.svg';
const marketplaces = [{
  icon: <Opensea />,
  name: 'OpenSea'
}, {
  icon: <Foundation />,
  name: 'Foundation'
}, {
  icon: <Fxhash />,
  name: 'Fxhash'
}, {
  icon: <Highlight />,
  name: 'Highlight'
}, {
  icon: <Looksrare />,
  name: 'Looksrare'
}, {
  icon: <Manifold />,
  name: 'Manifold'
}, {
  icon: <Superrare />,
  name: 'SuperRare'
}, {
  icon: <Zora />,
  name: 'Zora'
}];
function MarketplaceBanner() {
  const ref = useRef(null);
  const {
    scrollYProgress
  } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  });

  // transform scrollYProgress (0-1) to x translation (-100-0)
  const xTransform = useTransform(scrollYProgress, [0, 1], [0, -100]);
  return <div ref={ref} data-sentry-component="MarketplaceBanner" data-sentry-source-file="marketplaces-supported.tsx">
      <div className="side-faders" style={{
      maskImage: 'linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 95%)',
      overflow: 'hidden'
    }}>
        <motion.div style={{
        x: xTransform
      }} className="flex flex-row flex-wrap justify-around gap-6" data-sentry-element="unknown" data-sentry-source-file="marketplaces-supported.tsx">
          {marketplaces.map((marketplace, key) => <div key={key} className="brightness-1 flex w-28 hover:brightness-[4] dark:invert">
              {marketplace.icon}
            </div>)}
        </motion.div>
      </div>
    </div>;
}
export function MarketplacesSupported() {
  return <section className="container-alt flex flex-col gap-12" data-sentry-component="MarketplacesSupported" data-sentry-source-file="marketplaces-supported.tsx">
      <h3 className="font-display text-primary text-center text-xl">
        supporting the leading marketplaces for artists and art collectors
      </h3>

      <MarketplaceBanner data-sentry-element="MarketplaceBanner" data-sentry-source-file="marketplaces-supported.tsx" />
    </section>;
}